/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import Vue from 'vue';
import App from './App.vue';

// Vuesax Component Framework
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import Vuesax from 'vuesax';
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax);

Vue.use(require('vue-moment'));
Vue.use(require('vue-shortkey'));

Vue.config.productionTip = false;

import PouchVue from 'pouch-vue';
import PouchAuthentication from 'pouchdb-authentication';
import PouchDB from 'pouchdb-browser';
import PouchFind from 'pouchdb-find';
import PouchLiveFind from 'pouchdb-live-find';

import VueBarcodeScanner from 'vue-barcode-scanner';

let options = {
  sensitivity: 300, // default is 100
  requiredAttr: true, // default is false
  // controlSequenceKeys: ['NumLock', 'Clear', 'Enter'], // default is null
  callbackAfterTimeout: true, // default is false
};

Vue.use(VueBarcodeScanner, options);

PouchDB.plugin(PouchFind);
PouchDB.plugin(PouchLiveFind);
PouchDB.plugin(PouchAuthentication);
// PouchDB.plugin(require("transform-pouch"));

// var pouch = new PouchDB("express");
// pouch.transform({
//   incoming: (doc) => {
//     return doc;
//   },
//   outgoing: (doc) => {
//     return doc;
//   },
// });

Vue.use(PouchVue, {
  pouch: PouchDB,
  defaultDB: 'express',
  optionDB: {},
});

import VueSession from 'vue-session';
Vue.use(VueSession, {
  persist: true,
});

// axios
import axios from './axios.js';
Vue.prototype.$http = axios;

// API Calls
import './http/requests';

// Theme Configurations
import '../themeConfig.js';

// ACL
import acl from './acl/acl';

// Globally Registered Components
import './globalComponents.js';

// Styles: SCSS
import './assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from './router';

// Vuex Store
import store from './store/store';

// i18n
import i18n from './i18n/i18n';

// Vuexy Admin Filters
import './filters/filters';

// Clipboard
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// Tour
import VueTour from 'vue-tour';
Vue.use(VueTour);
require('vue-tour/dist/vue-tour.css');

// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer';
Vue.use(VueHammer);

// import ElectronStore from 'electron-store'
// Vue.use(new ElectronStore());

import VueAnime from 'vue-animejs';

Vue.use(VueAnime);

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

// Feather font icon
require('./assets/css/iconfont.css');

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

import VueHtmlToPaper from 'vue-html-to-paper';

import idb from '@/db/idb';

const VueHtmlToPaperOptions = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    // "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    // "https://unpkg.com/kidlat-css/css/kidlat.css",
    'material-icons/iconfont/material-icons.css',
    'https://cdn.jsdelivr.net/npm/vuesax/dist/vuesax.css',
    'https://express.cw.co.ke/assets/css/main.css',
    'https://express.cw.co.ke/assets/css/iconfont.css',
  ],
  timeout: 5000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

Vue.use(VueHtmlToPaper, VueHtmlToPaperOptions);

Vue.mixin({
  data() {
    return {
      logo: '',
    };
  },
  methods: {
    getLogo(docId) {
      return this.$pouch.getAttachment(docId, 'logo');
    },
  },
});

// Vue.prototype.$getLogo = () =>
//   new Promise((resolve, reject) => {
//     console.log('hello', this);

//     resolve(this.$pouch.getAttachment(docId, 'logo'));
//   });

// const shared = {
//   message: "my global message"
// }

// shared.install = function(){
//   Object.defineProperty(Vue.prototype, '$myGlobalStuff', {
//     get (docId) { return this.$pouch.getAttachment(docId, 'logo') }
//   })
// }

// Vue.use(shared);

// import { appWindow } from '@tauri-apps/api/window';
// import { version } from "../package.json";

Vue.config.productionTip = false;

var vm = new Vue({
  data: {
    version: ' v' + 'version',
    commit: '',
    logo: null,
    receiptLogo: null,
    logoBlob: null,
    logoBuffer: null,
    logoUrl: null,
    appWindow: null, //appWindow,
    type: 'electron', // tauri
    machineID: null,
  },
  async created() {
    await this.getMachineID();
    await this.checkActivationStatus();
  },
  computed: {
    currentWindow() {
      if (this.type == 'electron') {
        return this.electronRemote;
      } else {
        return this.appWindow;
      }
    },
  },
  methods: {
    async getMachineID() {
      var k = await idb.getRecord('installationID');
      if (k) {
        this.machineID = k.data;
      }
    },
    async checkActivationStatus() {
      try {
        let activation = await idb.getRecord('activation');

        // console.log('this.machineID', this.machineID);
        // console.log('route activation', activation);

        const activationData = await this.$pouch.get(this.machineID);

        // console.log(activationData);

        if (activationData) {
          var e = this.$moment(this.activationData.expiry).isBefore(
            new Date(Date.now()),
          );
          if (e) {
            // Deactivate license
            // Redirect to license expired
            let activation = (await idb.getRecord('activation')) || {};

            await idb.saveRecord({
              ...activation,
              type: 'activation',
              active: false,
              expiry: new Date(Date.now()).setHours(0, 0, 0, 0),
            });

            await this.$pouch.put({
              ...this.activationData,
              active: false,
            });
          }
        }
      } catch (error) {
        console.error('Error checking activation status:', error);
      }
    },
    getReceipt(id, entity) {
      switch (entity) {
        case 'purchase':
          this.$router.push(`/store/purchases/${id}/receipt`).catch(() => {});
          break;

        default:
          this.$router.push(`/store/sales/${id}/receipt`).catch(() => {});
          break;
      }
    },
    isNative() {
      if (this.type == 'electron') {
        console.log(this.currentWindow);
        return this.currentWindow != null;
      } else {
        return window.__TAURI__;
      }
    },
    async getLogo() {
      return new Promise((resolve, reject) => {
        try {
          this.$pouch
            .getAttachment('business', 'logo')
            .then(async (res) => {
              this.logoBlob = res;
              this.logoBuffer = await this.blobToUint8Array(res);
              this.logo = await this.blobToBase64(res);
              this.logoUrl = URL.createObjectURL(res);

              resolve(res);
            })
            .catch((err) => {
              throw err;
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    async blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async blobToUint8Array(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const arrayBuffer = reader.result;
          const uint8Array = new Uint8Array(arrayBuffer);
          resolve(uint8Array);
        };

        reader.onerror = () => {
          reject(new Error('Error reading blob as ArrayBuffer'));
        };

        reader.readAsArrayBuffer(blob);
      });
    },
    closeApp(currentWindow) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Close POS',
        text: 'You are about to close Express POS. Any unsaved data would be lost. Are you sure you want to proceed?',
        accept: () => {
          currentWindow.close();
          // this.appWindow.close();
        },
      });
    },
  },
  beforeDestroy() {
    if (this.logoUrl) {
      URL.revokeObjectURL(this.logoUrl);
    }
  },
  async mounted() {
    await this.getLogo();
  },
  watch: {
    async 'businessData._attachments.logo'(val) {
      if (!val) {
        this.logo = null;
        return;
      }
      await this.getLogo();
    },
  },
  pouch: {
    activationData() {
      return {
        database: 'express',
        selector: { type: 'activation', _id: this.machineID },
        first: true,
      };
    },
    businessData() {
      return {
        database: 'express',
        selector: { _id: 'business' },
        first: true,
      };
    },
    settingsData() {
      return {
        database: 'express',
        selector: { _id: 'settings' },
        first: true,
      };
    },
  },
  router,
  store,
  i18n,
  acl,
  mixins: [], //[helperPlugin],
  render: (h) => h(App),
}).$mount('#app');

export default vm;
