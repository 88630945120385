/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import vm from '../../main.js';

export default {
  addBranch({ commit }, branch) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      vm.$pouch
        .post(
          {
            branch_name: branch.branch_name,
            branch_phone: branch.branch_phone,
            type: 'branch',
            date_created: timestamp,
            date_updated: timestamp,
            active: true,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  updateBranch({ commit }, branch) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      vm.$pouch
        .put(
          {
            _id: branch._id,
            _rev: branch._rev,
            branch_name: branch.branch_name,
            branch_phone: branch.branch_phone,
            type: 'branch',
            date_updated: timestamp,
            active: true,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },

  removeBranch({ commit }, branch) {
    return new Promise((resolve, reject) => {
      vm.$pouch
        .remove(branch)
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
};
