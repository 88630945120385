import crypto from 'crypto';

const K = '2IXRhaKYZEJvr1s6t+tQMN9yYvhr4j3eZk1zJlMgXOXFr0mOncA3UnLVNQPwsitK';
const DB_NAME = 'expressdb';
const DB_VERSION = 1;
let DB;

export default {

    async getDb() {
        return new Promise((resolve, reject) => {

            if (DB) {
                return resolve(DB);
            }
            console.log('OPENING DB', DB);
            let request = window.indexedDB.open(DB_NAME, DB_VERSION);

            request.onerror = e => {
                console.log('Error opening db', e);
                reject('Error');
            };

            request.onsuccess = e => {
                DB = e.target.result;
                resolve(DB);
            };

            request.onupgradeneeded = e => {
                console.log('onupgradeneeded');
                let db = e.target.result;
                db.createObjectStore("expressdb", {
                    autoIncrement: true,
                    keyPath: 'id'
                });
            };
        });
    },
    async deleteRecord(record) {

        let db = await this.getDb();

        return new Promise(resolve => {

            let trans = db.transaction(['expressdb'], 'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('expressdb');
            store.delete(record.id);
        });
    },
    async getRecords() {

        let db = await this.getDb();

        return new Promise(resolve => {

            let trans = db.transaction(['expressdb'], 'readonly');
            trans.oncomplete = () => {
                resolve(records);
            };

            let store = trans.objectStore('expressdb');
            let records = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    records.push(cursor.value)
                    cursor.continue();
                }
            };

        });
    },
    async getRecord(type) {

        let db = await this.getDb();

        return new Promise(resolve => {

            let trans = db.transaction(['expressdb'], 'readonly');
            trans.oncomplete = () => {
                var rs = this.f(records.find((e) => {
                    var r = this.f(e);
                    if (r.type === type) return true;
                    return false
                }));
                resolve(rs);
            };

            let store = trans.objectStore('expressdb');
            let records = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    records.push(cursor.value)
                    cursor.continue();
                }
            };

        });
    },

    async saveRecord(record) {

        let db = await this.getDb();

        return new Promise(async (resolve) => {
            let data = await this.getRecords();
            let d = data.find((e) => {
                var r = this.f(e);
                if (r.type === record.type) return true;
                return false;
            });

            if (d) {
                this.deleteRecord(d);
            }

            let trans = db.transaction(['expressdb'], 'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('expressdb');
            store.put(this.t(record));
        });

    },

    t(s) {
        const algo = "aes-256-cbc";
        const sk = crypto.randomBytes(32);
        const iv = crypto.randomBytes(16);
        const cipher = crypto.createCipheriv(algo, Buffer.from(sk), iv);
        const en = Buffer.concat([cipher.update(btoa(JSON.stringify(s))), cipher.final()]);
        return {
            x: sk.toString("hex"),
            y: iv.toString("hex"),
            z: en.toString("hex"),
        };
    },

    f(s) {
        if (typeof s === 'object') {
            let key = Buffer.from(s.x, "hex");
            let iv = Buffer.from(s.y, "hex");
            let d = Buffer.from(s.z, "hex");
            let decipher = crypto.createDecipheriv(
                "aes-256-cbc",
                Buffer.from(key),
                iv
            );
            let de = decipher.update(d);
            de = Buffer.concat([de, decipher.final()]);
            return JSON.parse(atob(de.toString()));
        }
        return null;
    }

}