/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import vm from '../../main.js';

import axios from '@/axios.js';
const { endpoint, activationFee } = require('@/../config.js');

export default {
  addProduct({ commit }, { stock, ...product }) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      vm.$pouch
        .post(
          {
            ...product,
            type: 'product',
            date_created: timestamp,
            date_updated: timestamp,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            // commit('ADD_PRODUCT', Object.assign(i, {
            //   id: res._id
            // }))
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  updateProduct({ commit }, product) {
    return new Promise((resolve, reject) => {
      vm.$pouch
        .put(
          {
            ...product,
            type: 'product',
            date_updated: Date.now(),
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            // commit('UPDATE_PRODUCT', res)
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  addProductStock({ commit }, update) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      vm.$pouch
        .post(
          {
            ...update,
            type: 'stock',
            date_created: timestamp,
            valid: true,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            // commit('ADD_PRODUCT', Object.assign(i, {
            //   id: res._id
            // }))
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  updateProductStock({ commit }, update) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      vm.$pouch
        .put(
          {
            ...update,
            type: 'stock',
            date_updated: timestamp,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            // commit('ADD_PRODUCT', Object.assign(i, {
            //   id: res._id
            // }))
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  addProductStockUpdate({ commit }, update) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      vm.$pouch
        .post(
          {
            ...update,
            _id: update._id || undefined,

            valid: true,
            type: 'stock_update',
            date_created: timestamp,
            date_updated: timestamp,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            // commit('ADD_PRODUCT', Object.assign(i, {
            //   id: res._id
            // }))
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  deleteProductStockUpdate({ commit }, stockUpdate) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      vm.$pouch
        .remove(stockUpdate._id, stockUpdate._rev, 'express')
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  migrateV1Stock({ commit }, migration) {
    return new Promise((resolve, reject) => {
      vm.$pouch
        .bulkDocs(migration, {}, 'express')
        .then((res) => {
          return resolve(res);
        })
        .catch((err2) => {
          return reject(err2);
        });
    });
  },
  /* *********** OLD API ************* */
  fetchProducts({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint + '/products/')
        .then((response) => {
          commit('SET_PRODUCTS', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // updateProduct({
  //   commit
  // }, method) {
  //   return new Promise((resolve, reject) => {
  //     axios.patch(endpoint + `/products/${method.id}/`, method)
  //       .then((response) => {
  //         commit('UPDATE_PRODUCT', response.data)
  //         resolve(response)
  //       })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },
  removeProduct({ commit }, product) {
    return new Promise((resolve, reject) => {
      vm.$pouch
        .remove(product)
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },

  // categories
  addCategory({ commit }, method) {
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint + '/categories/', method)
        .then((response) => {
          commit(
            'ADD_CATEGORY',
            Object.assign(response.data, {
              id: response.data.id,
            }),
          );
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchCategories({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint + '/categories/')
        .then((response) => {
          commit('SET_CATEGORIES', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCategory({ commit }, method) {
    return new Promise((resolve, reject) => {
      axios
        .patch(endpoint + `/categories/${method.id}/`, method)
        .then((response) => {
          commit('UPDATE_CATEGORY', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeCategory({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(endpoint + `/categories/${itemId}/`)
        .then((response) => {
          commit('REMOVE_CATEGORY', itemId);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
