/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import vm from '../../main.js';

import axios from '@/axios.js';
const { endpoint } = require('@/../config.js');

export default {
  addSale({ commit }, { products_raw, ...sale }) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      return vm.$pouch
        .put(
          {
            ...sale,

            _id: `${sale.branch._id}:${timestamp}`,

            amount: sale.amount,
            amountRaw: sale.amountRaw,
            currency: sale.currency,
            discount: sale.discount,
            branch: sale.branch,
            conversionRate: sale.conversionRate,
            currencyCode: sale.currencyCode,
            converted: sale.converted,

            isWholesale: sale.isWholesale,

            products: products_raw,
            payment: sale.payment_method,

            completed: !sale.credit,
            cashier: vm.$session.get('user')._id,

            type: 'sale',
            void: false,
            date_created: timestamp,
            date_updated: timestamp,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            if (sale.version == 2) {
              return resolve(res);
            } else if (sale.products_raw && sale.products_raw.length > 0) {
              vm.$pouch
                .bulkDocs(sale.products_raw, {}, 'express')
                .then((res2) => {
                  return resolve(res);
                })
                .catch((err2) => {
                  return reject(err2);
                });
            } else {
              return resolve(res);
            }
          } else {
            return resolve(res);
          }
        });
    });
  },
  fetchSale({ commit }, s) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint + '/sales/' + s + '/')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchSaleItems({ commit }, s) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint + '/api/sales/sale/' + s + '/items/')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchSales({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint + '/sales/')
        .then((response) => {
          commit('SET_SALES', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateSale({ commit }, method) {
    return new Promise((resolve, reject) => {
      axios
        .patch(endpoint + `/sales/${method.id}/`, method)
        .then((response) => {
          commit('UPDATE_SALE', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  voidSale({ commit }, sale) {
    return new Promise((resolve, reject) => {
      vm.$pouch
        .put(
          {
            ...sale,
            void: true,
            date_updated: Date.now(),
          },
          {},
          'express',
        )
        .then(async (res) => {
          if (res.ok) {
            // revert stock numbers
            var products_raw = [];
            for (var i = 0; i < sale.products.length; i++) {
              var p = sale.products[i];
              if (p != undefined || p != null) {
                p.stock[sale.branch._id] =
                  parseInt(p.stock[sale.branch._id]) + parseInt(p.q);

                const refreshedProduct = await vm.$pouch
                  .get(p._id, {}, 'express')
                  .then((pres) => {
                    return pres;
                  })
                  .catch(() => {
                    return null;
                  });

                if (refreshedProduct == null) {
                  continue;
                }

                products_raw.push({
                  ...p,
                  _rev: refreshedProduct._rev,
                  type: 'product',
                  date_updated: Date.now(),
                });
              }
            }

            return new Promise((resolve, reject) => {
              vm.$pouch
                .bulkDocs(products_raw, {}, 'express')
                .then((res2) => {
                  console.log('product res2', res2);
                  return resolve(res2);
                })
                .catch((err2) => {
                  console.log('product err2', err2);
                  return reject(err2);
                });
            });
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  completeSale({ commit }, sale) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      return vm.$pouch
        .put(
          {
            ...sale,
            credit: false,
            wasCredit: true,
            completed: true,
            date_updated: timestamp,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            //
          } else {
            return resolve(res);
          }
        });
    });
  },
  removeSale({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(endpoint + `/sales/${itemId}/`)
        .then((response) => {
          commit('REMOVE_SALE', itemId);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
