/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/


import Vue from 'vue'
import FeatherIcon from './components/FeatherIcon.vue'
import VxCard from './components/vx-card/VxCard.vue'
import VxInputGroup from './components/vx-input-group/VxInputGroup.vue'
import VxList from './components/vx-list/VxList.vue'
import VxBreadcrumb from './layouts/components/VxBreadcrumb.vue'
import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue'

Vue.component(VxTooltip.name, VxTooltip)
Vue.component(VxCard.name, VxCard)
Vue.component(VxList.name, VxList)
Vue.component(VxBreadcrumb.name, VxBreadcrumb)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VxInputGroup.name, VxInputGroup)


// v-select component
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'XIcon',
        svgClasses: 'w-4 h-4 mt-1'
      }
    }),
  },
  OpenIndicator: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'ChevronDownIcon',
        svgClasses: 'w-5 h-5'
      }
    }),
  },
});

Vue.component(vSelect)


// import VueQzTray from './components/print/vue-qz-tray'
// import VueQzTrayAction from './components/print/vue-qz-tray-action.vue'
// import VueQzTrayConnect from './components/print/vue-qz-tray-connect.vue'
// import VueQzTrayFormElement from './components/print/vue-qz-tray-form-element.vue'
// import VueQzTrayFormNested from './components/print/vue-qz-tray-form-nested.vue'
// import VueQzTrayInputIframe from './components/print/vue-qz-tray-input-iframe.vue'
// import VueQzTrayInput from './components/print/vue-qz-tray-input.vue'
// import VueQzTrayOptions from './components/print/vue-qz-tray-options.vue'
// import VueQzTrayPrinters from './components/print/vue-qz-tray-printers.vue'


const Components = {
    // VueQzTray,
    // VueQzTrayAction,
    // VueQzTrayConnect,
    // VueQzTrayFormElement,
    // VueQzTrayFormNested,
    // VueQzTrayInput,
    // VueQzTrayOptions,
    // VueQzTrayPrinters,
    // VueQzTrayInputIframe
};


Object.keys(Components).forEach(name => {
    Vue.component(name, Components[name]);
});
