/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/


import auth from "@/auth/authService";
import firebase from 'firebase/app'
import 'firebase/auth'

import vm from '../../main.js'

export default {
  isUserLoggedIn: (pouch) => {
    return pouch.getSession('express', (data) => {
      if (data.status == 0) {
        console.log("Unable to connect to the server. It might be offline.")
        return false;
      }

      if (!data.user || !data.hasAccess) {
        return false;
      }

      return true;
    })
  },
}