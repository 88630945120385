/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

import bcrypt from "bcryptjs";

import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
import store from "@/store/store.js";

import vm from '../../main.js'

export default {
  login({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      return vm.$pouch.find({
        selector: {
          username: payload.userDetails.username.toLowerCase()
        }
      }, "express").then((search) => {
        if (search.docs.length === 0) {
          return reject("User not found");
        } else {
          var user = search.docs[0];
          if (user.active === false) {
            return reject("You have been suspended from accessing this system. Please contact your supervisor.");
          } else {
            var validate = bcrypt.compareSync(
              payload.userDetails.password,
              user.password
            );
            if (!validate) {
              return reject("Invalid Password");
            } else {
              localStorage.setItem(
                "remember_me",
                payload.remember_me
              );
              if (payload.remember_me) {
                localStorage.setItem(
                  "username",
                  payload.userDetails.username
                );
              } else {
                localStorage.removeItem("username");
              }
              localStorage.setItem('loggedin', true);
              delete user.password;
              vm.$acl.change(user.role);
              vm.$session.start();
              vm.$session.set('user', user);
              router.push(router.currentRoute.query.to || "/");
              return resolve('Login Successful');
            }
          }
        }
      }).catch((err) => reject(err));
    });
  },
};