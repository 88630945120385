import axios from "../../../axios/index.js"
import store from "../../../../store/store.js"
import router from "@/router.js"
const { endpoint } = require('@/../config.js');

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export default {
  init() {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config

      // if (status === 401) {
      if (response && response.status === 401) {
        localStorage.removeItem('userInfo')
        localStorage.removeItem('loggedIn')
        router.push({name: 'login'}).catch(() => {})

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = 'Token ' + access_token
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }
      return Promise.reject(error)
    })
  },
  login(username, pwd) {
    return axios.post(endpoint+"/api/auth/login/", {username: username, password: pwd})
  },
  registerUser(first_name, last_name, email, phone, pwd, pwd2) {
    return axios.post(endpoint+"/api/auth/register/", {first_name: first_name, last_name: last_name, email: email, username: phone, password1: pwd, password2: pwd2})
  },
  refreshToken() {
    return axios.post(endpoint+"/api/auth/token/refresh/", {refresh: localStorage.getItem("refresh")})
  },

  authUser() {
    return axios.get(endpoint+"/api/auth/user/")
  },
}
