/*=========================================================================================
  File Name: module.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/


import state from './moduleState.js'
import mutations from './moduleMutations.js'
import actions from './moduleActions.js'
import getters from './moduleGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

