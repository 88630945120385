/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import vm from '../../main.js';

export default {
  addSupplier({ commit }, supplier) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      vm.$pouch
        .post(
          {
            supplier_name: supplier.supplier_name,
            supplier_address: supplier.supplier_address,
            supplier_phone: supplier.supplier_phone,
            supplier_alt_phone: supplier.supplier_alt_phone,
            type: 'supplier',
            date_created: timestamp,
            date_updated: timestamp,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  updateSupplier({ commit }, supplier) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      vm.$pouch
        .put(
          {
            _id: supplier._id,
            _rev: supplier._rev,
            supplier_name: supplier.supplier_name,
            supplier_address: supplier.supplier_address,
            supplier_phone: supplier.supplier_phone,
            supplier_alt_phone: supplier.supplier_alt_phone,
            type: 'supplier',
            date_updated: timestamp,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },

  removeSupplier({ commit }, supplier) {
    return new Promise((resolve, reject) => {
      vm.$pouch
        .remove(supplier)
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
};
