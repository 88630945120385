/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import vm from '../../main.js';

import axios from '@/axios.js';
const { endpoint, activationFee } = require('@/../config.js');

export default {
  addCustomer({ commit }, customer) {
    return new Promise((resolve, reject) => {
      vm.$pouch
        .post(
          {
            name: customer.name,
            phone: customer.phone,
            id_number: customer.id_number,
            address: customer.address,
            city: customer.city,
            wholesale: customer.wholesale,
            type: 'customer',
            date_created: Date.now(),
            date_updated: Date.now(),
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            // commit('ADD_CUSTOMER', Object.assign(customer, {
            //   id: res._id
            // }))
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  updateCustomer({ commit }, customer) {
    return new Promise((resolve, reject) => {
      vm.$pouch
        .put(
          {
            _id: customer._id,
            _rev: customer._rev,
            name: customer.name,
            phone: customer.phone,
            id_number: customer.id_number,
            address: customer.address,
            city: customer.city,
            wholesale: customer.wholesale,
            type: 'customer',
            date_updated: Date.now(),
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            // commit('UPDATE_CUSTOMER', res)
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  /* ********** OLD API ********* */
  fetchCustomers({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint + '/customers/')
        .then((response) => {
          commit('SET_CUSTOMERS', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeCustomer({ commit }, i) {
    return new Promise((resolve, reject) => {
      axios
        .delete(endpoint + `/customers/${i}/`)
        .then((response) => {
          commit('REMOVE_CUSTOMER', i);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
