/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import vm from '../../main.js';

import axios from '@/axios.js';
const { endpoint, activationFee } = require('@/../config.js');

export default {
  addPurchase({ commit }, purchase) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      return vm.$pouch
        .post(
          {
            order_id: purchase.order_id,
            amount: purchase.amount,
            delivery: purchase.delivery,
            fulfilled: purchase.fulfilled,
            products: purchase.products_raw,
            branch: purchase.branch,
            supplier: purchase.supplier,
            type: 'purchase',
            date_created: timestamp,
            date_updated: timestamp,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            if (purchase.version == 2) {
              return resolve(res);
            } else if (
              purchase.fulfilled &&
              purchase.products_raw &&
              purchase.products_raw.length > 0
            ) {
              return vm.$pouch
                .bulkDocs(purchase.products_raw, {}, 'express')
                .then((res2) => {
                  var valid = 0;
                  var errors = [];
                  for (var i = 0; i < res2.length; i++) {
                    if (res2[i].ok) {
                      valid++;
                    } else {
                      errors.push(res2[i].message);
                    }
                  }
                  return resolve(res);
                })
                .catch((err) => {
                  return reject(err);
                });
            } else {
              return resolve(res);
            }
          } else {
            return reject(res);
          }
        })
        .catch((err) => reject(err));

      // axios.post(endpoint+"/api/purchases/new/", method)
      //   .then((response) => {
      //     commit('ADD_PURCHASE', Object.assign(method, {id: response.data.id}))
      //     resolve(response)
      //   })
      //   .catch((error) => { reject(error) })
    });
  },
  fetchPurchase({ commit }, s) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint + '/purchases/' + s + '/')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPurchases({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint + '/purchases/')
        .then((response) => {
          commit('SET_PURCHASES', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPurchaseItems({ commit }, s) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint + '/api/purchases/' + s + '/items/')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePurchase({ commit }, method) {
    return new Promise((resolve, reject) => {
      axios
        .patch(endpoint + `/purchases/${method.id}/`, method)
        .then((response) => {
          commit('UPDATE_PURCHASE', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removePurchase({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(endpoint + `/purchases/${itemId}/`)
        .then((response) => {
          commit('REMOVE_PURCHASE', itemId);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
