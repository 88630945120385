/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import bcrypt from "bcryptjs";

import axios from "@/axios.js";
import vm from "../../main.js";

export default {
  addUser({
    commit
  }, user) {
    return new Promise((resolve, reject) => {
      return vm.$pouch.find({
        selector: {
          username: user.username.toLowerCase()
        }
      }, "express").then((res) => {
        if (res.docs.length === 0) {
          var timestamp = Date.now();
          return vm.$pouch
            .put({
                _id: `user:${timestamp}`,
                username: user.username.toLowerCase(),
                password: bcrypt.hashSync(user.password, 8),
                name: user.name,
                email: user.email,
                phone: user.phone,
                role: user.role,
                branch: user.branch,
                type: "user",
                date_created: Date.now(),
                date_updated: Date.now(),
              }, {},
              "express"
            )
            .then((res2) => {
              if (res2.ok) {
                return resolve(res2);
              } else {
                return reject(res2);
              }
            })
            .catch((err) => reject(err));
        } else {
          return reject("This username is already in use");
        }
      })
    });
  },

  // fetchUser(context, userId) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`/api/user-management/users/${userId}`)
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },
  removeRecord({
    commit
  }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/user-management/users/${userId}`)
        .then((response) => {
          commit("REMOVE_RECORD", userId);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};