/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/


export default {
  ADD_PRODUCT(state, i) {
    state.products.unshift(i)
  },
  SET_PRODUCTS(state, i) {
    state.products = i
  },
  UPDATE_PRODUCT(state, i) {
    const index = state.products.findIndex((m) => m._id == i._id)
    Object.assign(state.products[index], i)
  },
  REMOVE_PRODUCT(state, i) {
    const index = state.products.findIndex((j) => j._id == i)
    state.products.splice(index, 1)
  },

  ADD_CATEGORY(state, i) {
    state.categories.unshift(i)
  },
  SET_CATEGORIES(state, i) {
    state.categories = i
  },
  UPDATE_CATEGORY(state, i) {
    const index = state.categories.findIndex((m) => m.id == i.id)
    Object.assign(state.categories[index], i)
  },
  REMOVE_CATEGORY(state, i) {
    const index = state.categories.findIndex((j) => j.id == i)
    state.categories.splice(index, 1)
  },
}