/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import vm from '../../main.js';

export default {
  addOption({ commit }, option) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      vm.$pouch
        .post(
          {
            name: option.payment_option,
            status: option.status,
            branches: option.branches,
            type: 'PO',
            date_created: timestamp,
            date_updated: timestamp,
            active: true,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
  updateOption({ commit }, option) {
    return new Promise((resolve, reject) => {
      var timestamp = Date.now();
      vm.$pouch
        .put(
          {
            _id: option._id,
            _rev: option._rev,
            name: option.payment_option,
            status: option.status,
            branches: option.branches,
            type: 'PO',
            date_updated: timestamp,
            active: true,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },

  removeOption({ commit }, option) {
    return new Promise((resolve, reject) => {
      vm.$pouch
        .put(
          {
            _id: option._id,
            _rev: option._rev,
            _deleted: true,
            type: 'PO',
            date_updated: timestamp,
            active: false,
          },
          {},
          'express',
        )
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  },
};
