/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import Vue from 'vue';
import Vuex from 'vuex';

import actions from './actions.js';
import getters from './getters';
import mutations from './mutations';
import state from './state';

Vue.use(Vuex);

import moduleAuth from './auth/moduleAuth.js';
import moduleCustomers from './customers/module.js';
import moduleProducts from './products/module.js';
import modulePurchases from './purchases/module.js';
import moduleSales from './sales/module.js';

import moduleBranches from './branches/module';
import modulePaymentOptions from './payment/module';
import moduleSuppliers from './suppliers/module';
import moduleUsers from './users/module';

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    users: moduleUsers,
    suppliers: moduleSuppliers,
    branches: moduleBranches,
    payment_options: modulePaymentOptions,

    products: moduleProducts,
    customers: moduleCustomers,
    sales: moduleSales,
    purchases: modulePurchases,
  },
  strict: process.env.NODE_ENV !== 'production',
});
