'use strict';

import idb from '@/db/idb';
import store from '@/store/store';

export default {
  init: async function(p) {
    var db = await idb.getRecord('database');
    if (db && db.db && db.allow_backups) {
      console.log("Starting Sync");
      let d = db.db;
      var sync = p
      .sync("express", `${d}`, {
        live: true,
        retry: true,
      })

    sync.on("connected", (d) => {
      console.log("Successfully connected to remote")
      store.dispatch('changeDBState', {
        pulling: false,
        pushing: false,
        idle: true,
        a: false,
        m: 'success',
        s: 'Database Connected Successfully',
      })
    })

    sync.on("change", (info) => {
      console.log("Changes detected ", info);
      store.dispatch('changeDBState', {
        pulling: info.direction === 'pull',
        pushing: info.direction === 'push',
        remaining: info.change.pending,
        idle: false,
        a: false,
        m: 'success',
        s: `Database ${info.direction === 'pull' ? 'download' : 'upload'} in progress  ${info.direction === 'pull' ? '(Remaining Records '+ info.change.pending + ')' : ''}`
      })
    })
      
    sync.on("paused", (err) => {
      console.log("Sync paused ", err);
      if (err) {
        sync.cancel();
      }
      store.dispatch('changeDBState', {
        pulling: false,
        pushing: false,
        idle: true,
        a: false,
        m: 'primary',
        s: 'Database Idle'
      })
    })
      
    sync.on("active", () => {
      console.log("Replication resumed");
      store.dispatch('changeDBState', {
        pulling: false,
        pushing: false,
        idle: true,
        a: false,
        m: 'success',
        s: `Database sync started`
      })
    })
      
    sync.on("denied", (err) => {
      console.log("A document failed to replicate", err);
      store.dispatch('changeDBState', {
        pulling: false,
        pushing: false,
        idle: true,
        a: true,
        m: 'warning',
        s: 'Failed to sync database, ' + err.message
      })
    })
      
    sync.on("complete", (info) => {
      console.log("Sync complete ", info);
      // sync.cancel();
      store.dispatch('changeDBState', {
        pulling: false,
        pushing: false,
        idle: true,
        a: false,
        m: 'primary',
        s: 'Database Idle'
      })
    })
      
    sync.on("error", (info) => {
      console.log("Sync error ", info);
      store.dispatch('changeDBState', {
        pulling: false,
        pushing: false,
        idle: true,
        a: true,
        m: 'warning',
        s: 'Failed to sync database, ' + info
      })
    });
    }
  },
};