import vm from '../main.js';

const actions = {
  updateLogo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      vm.$pouch
        .get('business', {}, 'express')
        .then(async (doc) => {
          console.log('doc', doc);
          await vm.$pouch
            .put(
              {
                ...doc,
                _id: 'business',
                _rev: doc._rev,
                _attachments: {
                  logo: {
                    content_type: payload.properties.type,
                    data: payload.data,
                  },
                },
                date_updated: Date.now(),
              },
              {},
              'express',
            )
            .then((res) => {
              if (res.ok) {
                resolve(res);
              } else {
                reject(res);
              }
            })
            .catch((err) => reject(err));
        })
        .catch(() => reject(err));
    });
  },
  updateBusinessSettings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      vm.$pouch
        .get('business', {}, 'express')
        .then((doc) =>
          vm.$pouch
            .put(
              {
                ...doc,
                _id: 'business',
                _rev: doc._rev,
                data: payload,
                date_updated: Date.now(),
              },
              {},
              'express',
            )
            .then((res) => {
              if (res.ok) {
                resolve(res);
              } else {
                reject(res);
              }
            })
            .catch((err) => reject(err)),
        )
        .catch(() =>
          vm.$pouch
            .put(
              {
                _id: 'business',
                data: payload,
                date_updated: Date.now(),
              },
              {},
              'express',
            )
            .then((res) => {
              if (res.ok) {
                resolve(res);
              } else {
                reject(res);
              }
            })
            .catch((err) => reject(err)),
        );
    });
  },
  async updateSettings({ commit }, payload) {
    try {
      const doc = await vm.$pouch.get('settings', {}, 'express');
      const response = await vm.$pouch.put(
        {
          ...doc,
          _id: 'settings',
          _rev: doc._rev,
          data: payload,
          date_updated: Date.now(),
        },
        {},
        'express',
      );

      if (response.ok) {
        commit('SET_SETTINGS', payload);
        return response;
      }
    } catch (error) {
      if (error.status === 404) {
        try {
          const response = await vm.$pouch.put(
            {
              _id: 'settings',
              data: payload,
              date_updated: Date.now(),
            },
            {},
            'express',
          );

          if (response.ok) {
            commit('SET_SETTINGS', payload);
            return response;
          }
        } catch (err) {
          console.error('Error creating settings:', err);
          throw err;
        }
      } else {
        console.error('Error updating settings:', error);
        throw error;
      }
    }
  },

  async updateSetting({ commit, state }, { key, value }) {
    try {
      const doc = await vm.$pouch.get('settings', {}, 'express');
      const updatedSettings = { ...doc.data.settings, [key]: value };

      const response = await vm.$pouch.put(
        {
          ...doc,
          _id: 'settings',
          _rev: doc._rev,
          data: updatedSettings,
          date_updated: Date.now(),
        },
        {},
        'express',
      );

      if (response.ok) {
        commit('UPDATE_SETTING', { key, value });
        return response;
      }
    } catch (error) {
      console.error('Error updating setting:', error);
      throw error;
    }
  },

  async updateSettingBulk({ commit, state }, changes) {
    try {
      const doc = await vm.$pouch
        .get('settings', {}, 'express')
        .catch(async (err) => {
          console.log('get err', err);
          if (err.name == 'not_found') {
            return vm.$pouch
              .post(
                {
                  _id: 'settings',
                },
                {},
                'express',
              )
              .then((res) => {
                console.log(res.id);
                return {
                  _id: res.id,
                  _rev: res.rev,
                };
              });
          }
        });
      let updatedSettings = { ...doc.data };
      changes.forEach(({ key, value }) => {
        updatedSettings[key] = value;
      });

      const response = await vm.$pouch.put(
        {
          ...doc,
          _id: 'settings',
          _rev: doc._rev,
          data: updatedSettings,
          date_updated: Date.now(),
        },
        {},
        'express',
      );

      if (response.ok) {
        return response;
      }
    } catch (error) {
      console.error('Error updating setting:', error);
      throw error;
    }
  },

  changeDBState({ commit }, payload) {
    commit('DB_STATE', payload);
  },
  changeAuth({ commit }, payload) {
    const { status } = payload;
    return new Promise((resolve, reject) => {
      commit('CHANGE_AUTH_STATUS', status);
      resolve(token);
    }).catch((error) => {
      reject(error);
    });
  },

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width);
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload);
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list);
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY');
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val);
  },
  // /////////////////////////////////////////////
  // Database
  // /////////////////////////////////////////////
  updateSyncStatus({ commit }, val) {
    commit('UPDATE_SYNC_STATUS', val);
  },
  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload);
  },
  updateUserRole({ dispatch }, payload) {
    // Change client side
    payload.aclChangeRole(payload.userRole);

    // Make API call to server for changing role

    // Change userInfo in localStorage and store
    dispatch('updateUserInfo', {
      userRole: payload.userRole,
    });
  },
};

export default actions;
