import Vue from "vue"
import {
  AclInstaller,
  AclCreate,
  AclRule
} from "vue-acl"
import router from "@/router"

Vue.use(AclInstaller)

let initialRole = "public"

var sesobj = localStorage.getItem("vue-session-key");
var session = JSON.parse(sesobj ? sesobj : "{}");
let userInfo = session.user ? session.user : {};
if (userInfo && userInfo.role) initialRole = userInfo.role

export default new AclCreate({
  initial: initialRole,
  notfound: "/not-authorized",
  router,
  acceptLocalRules: true,
  globalRules: {
    public: new AclRule("admin").or("cashier").or("public").generate(),
    cashier: new AclRule("admin").or("cashier").generate(),
    admin: new AclRule("admin").generate(),
  }
})