/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/


export default {
  ADD_SALE(state, i) {
    state.sales.unshift(i)
  },
  SET_SALES(state, i) {
    state.sales = i
  },
  UPDATE_SALE(state, i) {
      const index = state.sales.findIndex((m) => m.id == i.id)
      Object.assign(state.sales[index], i)
  },
  REMOVE_SALE(state, i) {
      const index = state.sales.findIndex((j) => j.id == i)
      state.sales.splice(index, 1)
  },
}
