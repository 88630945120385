/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/

import Vue from 'vue';
import Router from 'vue-router';

import moment from 'moment';
import idb from './db/idb';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/dashboard',
        },
        {
          path: '/index',
          redirect: '/login',
        },
        {
          path: '/index.html',
          redirect: '/login',
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('./views/Dashboard.vue'),
          meta: {
            rule: 'cashier',
            authRequired: true,
          },
        },

        {
          path: '/print',
          name: 'test-print',
          component: () => import('./views/pages/sales/print.vue'),
          meta: {
            rule: 'admin',
            authRequired: true,
          },
        },
        {
          path: '/store/customers',
          name: 'customers',
          component: () => import('./views/pages/customers/List.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Customers',
                active: true,
              },
            ],
            pageTitle: 'Customers',
            rule: 'cashier',
            authRequired: true,
          },
        },
        {
          path: '/store/customers/:id',
          name: 'customer-profile',
          component: () => import('./views/pages/customers/Profile.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Customers',
                url: '/store/customers',
              },
              {
                title: 'Profile',
                active: true,
              },
            ],
            pageTitle: 'Customer Profile',
            rule: 'admin',
            authRequired: true,
          },
        },
        {
          path: '/store/items',
          name: 'items',
          component: () => import('./views/pages/items/List.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Items',
                active: true,
              },
            ],
            pageTitle: 'Items',
            rule: 'cashier',
            authRequired: true,
          },
        },
        {
          path: '/store/purchases',
          name: 'purchase',
          component: () => import('./views/pages/purchases/List.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Purchases',
                active: true,
              },
            ],
            pageTitle: 'Purchases',
            rule: 'admin',
            authRequired: true,
          },
        },
        {
          path: '/store/purchases/new',
          name: 'new-purchase',
          component: () => import('./views/pages/purchases/New.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Purchases',
                url: '/store/purchases',
              },
              {
                title: 'New Purchase',
                active: true,
              },
            ],
            pageTitle: 'New Purchase',
            rule: 'admin',
            authRequired: true,
          },
        },
        {
          path: '/store/purchases/:id/receipt',
          name: 'purchases-receipt',
          component: () => import('./views/pages/purchases/Receipt.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Purchases',
                url: '/store/purchases',
              },
              {
                title: 'Receipt',
                active: true,
              },
            ],
            pageTitle: 'Receipt',
            rule: 'admin',
            authRequired: false,
          },
        },
        {
          path: '/store/report',
          name: 'report',
          component: () => import('./views/pages/reports/report.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Report',
                active: true,
              },
            ],
            pageTitle: 'Report',
            rule: 'admin',
            authRequired: true,
          },
        },

        //  {
        //   path: '/store/report/supplier',
        //   name: 'report-supplier',
        //   component: () => import('./views/pages/reports/suppliers.vue'),
        //   meta: {
        //     breadcrumb: [
        //       {
        //         title: 'Dashboard',
        //         url: '/',
        //       },
        //       {
        //         title: 'Store',
        //       },
        //       {
        //         title: 'Reports',
        //         url: '/store/report'
        //       },
        //        {
        //         title: 'Supplier Report',
        //         active: true,
        //       },
        //     ],
        //     pageTitle: 'Supplier Report',
        //     rule: 'admin',
        //     authRequired: true,
        //   },
        // },

        {
          path: '/system/users',
          name: 'users',
          component: () => import('./views/pages/system/users/List.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'System',
              },
              {
                title: 'Users',
                active: true,
              },
            ],
            pageTitle: 'Users',
            rule: 'admin',
            authRequired: true,
          },
        },

        {
          path: '/system/settings',
          name: 'settings',
          component: () => import('./views/pages/settings/Settings.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'System',
              },
              {
                title: 'Settings',
                active: true,
              },
            ],
            pageTitle: 'Settings',
            rule: 'admin',
            authRequired: true,
          },
        },
        {
          path: '/store/suppliers',
          name: 'suppliers',
          component: () => import('./views/pages/suppliers/List.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Suppliers',
                active: true,
              },
            ],
            pageTitle: 'Suppliers',
            rule: 'admin',
            authRequired: true,
          },
        },
        {
          path: '/store/suppliers/:id',
          name: 'supplier-profile',
          component: () => import('./views/pages/suppliers/Profile.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Suppliers',
                url: '/store/suppliers',
              },
              {
                title: 'Profile',
                active: true,
              },
            ],
            pageTitle: 'Supplier Profile',
            rule: 'admin',
            authRequired: true,
          },
        },
        {
          path: '/store/branches',
          name: 'branches',
          component: () => import('./views/pages/branches/List.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Branches',
                active: true,
              },
            ],
            pageTitle: 'Branches',
            rule: 'admin',
            authRequired: true,
          },
        },
        {
          path: '/store/payment-options',
          name: 'payment-options',
          component: () => import('./views/pages/payment/List.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Payment Options',
                active: true,
              },
            ],
            pageTitle: 'Payment Options',
            rule: 'admin',
            authRequired: true,
          },
        },
        {
          path: '/store/sales',
          name: 'sales',
          component: () => import('./views/pages/sales/List.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Sales',
                active: true,
              },
            ],
            pageTitle: 'Sales',
            rule: 'cashier',
            authRequired: true,
          },
        },
        {
          path: '/store/sales/new',
          name: 'sales-new',
          component: () => import('./views/pages/sales/New.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Sales',
                url: '/store/sales',
              },
              {
                title: 'New Sale',
                active: true,
              },
            ],
            pageTitle: 'New Sale',
            rule: 'cashier',
            authRequired: true,
          },
        },

        {
          path: '/store/sales/new/customer/:customerId',
          name: 'sales-new-customer',
          component: () => import('./views/pages/sales/New.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Sales',
                url: '/store/sales',
              },
              {
                title: 'New Sale',
                active: true,
              },
            ],
            pageTitle: 'New Sale',
            rule: 'cashier',
            authRequired: true,
          },
        },

        {
          path: '/store/sales/:id/receipt',
          name: 'sales-receipt',
          component: () => import('./views/pages/sales/Receipt.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Dashboard',
                url: '/',
              },
              {
                title: 'Store',
              },
              {
                title: 'Sales',
                url: '/store/sales',
              },
              {
                title: 'Receipt',
                active: true,
              },
            ],
            pageTitle: 'Receipt',
            rule: 'cashier',
            authRequired: false,
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/license',
          name: 'license',
          component: () => import('@/views/pages/system/License.vue'),
          meta: {
            rule: 'public',
            authRequired: false,
          },
        },
        {
          path: '/welcome',
          name: 'welcome',
          component: () =>
            import('@/views/pages/system/installation/start.vue'),
          meta: {
            rule: 'public',
            authRequired: false,
          },
        },
        {
          path: '/generate/:id',
          name: 'generate',
          component: () =>
            import('@/views/pages/system/installation/generate.vue'),
          meta: {
            rule: 'public',
            authRequired: false,
          },
        },
        {
          path: '/install',
          name: 'install',
          component: () =>
            import('@/views/pages/system/installation/install.vue'),
          meta: {
            rule: 'public',
            authRequired: false,
          },
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/soon',
          name: 'soon',
          component: () => import('@/views/pages/ComingSoon.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'public',
          },
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'public',
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
    // {
    //   path: '*',
    //   redirect: '/pages/error-404',
    // },
    { path: '*', redirect: '/' },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

router.beforeEach(async (to, from, next) => {
  let activation = await idb.getRecord('activation');
  let installation = await idb.getRecord('installation');

  if (
    to.path === '/welcome' ||
    to.path === '/install' ||
    to.path === '/license' ||
    to.name === 'generate' ||
    to.path === '/soon'
  ) {
    return next();
  } else {
    if (!activation && !installation) {
      return router.push({
        name: 'welcome',
      });
    }

    if (
      (!activation && !installation.complete) ||
      (installation && !activation) ||
      (!installation && activation)
    ) {
      return router.push({
        name: 'install',
      });
    }
    if (installation && activation && !activation.active) {
      if (from.name !== 'license') {
        return router.push({
          name: 'license',
        });
      }
    }
    if (activation) {
      var e = moment(activation.expiry).isBefore(new Date(Date.now()));
      var s = moment(new Date()).isAfter(new Date(activation.start));
      if (e) {
        console.log('License Expired');
        await idb.saveRecord({
          type: 'activation',
          active: false,
          license: activation.license,
          start: activation.start,
          expiry: activation.expiry,
          date: new Date(Date.now()),
        });
        if (from.name !== 'license') {
          return router.push({
            name: 'license',
          });
        }
      }

      if (!s) {
        return router.push({
          name: 'soon',
        });
      }
    }
  }

  if (to.meta.authRequired) {
    var session = JSON.parse(localStorage.getItem('vue-session-key'));
    if (session == null || session.user == null || session.user._id == null) {
      return router.push({
        name: 'login',
        query: {
          to: to.path,
        },
      });
    }
  }

  return next();

  // firebase.auth().onAuthStateChanged(() => {
  //   // get firebase current user
  //   const firebaseCurrentUser = firebase.auth().currentUser;

  //   // if (
  //   //     to.path === "/pages/login" ||
  //   //     to.path === "/pages/forgot-password" ||
  //   //     to.path === "/pages/error-404" ||
  //   //     to.path === "/pages/error-500" ||
  //   //     to.path === "/pages/register" ||
  //   //     to.path === "/callback" ||
  //   //     to.path === "/pages/comingsoon" ||
  //   //     (auth.isAuthenticated() || firebaseCurrentUser)
  //   // ) {
  //   //     return next();
  //   // }

  //   // If auth required, check login. If login fails redirect to login page
  //   if (to.meta.authRequired) {
  //     if (!(auth.isAuthenticated() || firebaseCurrentUser)) {
  //       router.push({
  //         name: "login",
  //         query: {
  //           to: to.path
  //         }
  //       });
  //     }
  //   }

  //   return next();
  //   // Specify the current path as the customState parameter, meaning it
  //   // will be returned to the application after auth
  //   // auth.login({ target: to.path });
  // });
});

export default router;
