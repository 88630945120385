/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
==========================================================================================*/


export default {
  ADD_CUSTOMER(state, i) {
    state.customers.unshift(i)
  },
  SET_CUSTOMERS(state, i) {
    state.customers = i
  },
  UPDATE_CUSTOMER(state, i) {
      const index = state.customers.findIndex((m) => m.id == i.id)
      Object.assign(state.customers[index], i)
  },
  REMOVE_CUSTOMER(state, i) {
      const index = state.customers.findIndex((j) => j.id == i)
      state.customers.splice(index, 1)
  },
}
